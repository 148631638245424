<template>
  <div>
    <h2>Affiliates</h2>
    <div class="row">
      <div class="col-12 col-lg-6 mb-3 mb-lg-0">
        <search-filter
          :showSearch="true"
          :disabled="areFiltersDisabled"
          v-model="search"
        >
        </search-filter>
      </div>
      <div class="col-12 col-lg-6 d-flex justify-content-end search-actions">
        <div class="row">
          <b-button-group
            v-if="true || !areFiltersDisabled"
            class="col-12 col-lg-auto mb-3 mb-lg-0"
            >
            <b-button
              v-bind:class="{ active: filters.status == 'approved' }"
              @click="setStatus('approved')"
              :disabled="areFiltersDisabled"
            >
              Approved
            </b-button>
            <b-button
              v-bind:class="{ active: filters.status == 'pending' }"
              @click="setStatus('pending')"
              :disabled="areFiltersDisabled"
            >
              Pending
            </b-button>
            <b-button v-bind:class="{ active: filters.status == 'rejected' }"
              @click="setStatus('rejected')"
              :disabled="areFiltersDisabled"
            >
              Rejected
            </b-button>
          </b-button-group>
          <filtering
            class="col-12 col-lg-auto mb-3 mb-lg-0"
            :current-filters="filters"
            :disabled="areFiltersDisabled"
            @filtersSet="setFilters"
            @initFilters="initFilters"
          ></filtering>
        </div>
      </div>
    </div>
    <div class="row input-row mt-4">
      <div class="col-12">
        <ifac-data-table
          v-if="items.length > 0"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :items="items"
          :total-rows="totalRows"
          :from="from"
          :to="to"
          :sort-by="sortBy"
          :sort-desc="direction === 'desc'"
          @row-selected="onCellClicked"
          @currentPageUpdated="currentPage = $event"
          @sort-by="setSortBy"
        />
        <ifac-loader v-if="refreshing"></ifac-loader>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <b-button :disabled="exportCsvDisabled" @click="onBtnExport()">
          {{ exportCsvCaption }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { IfacDataTable, IfacLoader } from '@ifac/ui';
import Affiliates from '@/services/Api/Affiliates';
import SearchFilter from '@/views/components/grids/SearchFilter.vue';
import Filtering from '@/views/areas/affiliates/index-partials/Filtering.vue';
import { debounce } from 'lodash';

export default {
  name: 'Affiliates',
  components: {
    SearchFilter,
    Filtering,
    IfacDataTable,
    IfacLoader,
  },
  mixins: [
  ],
  data() {
    return {
      columnDefs: null,
      rowData: [],
      refreshing: false,
      exporting: false,
      defaultExcelExportParams: null,
      filters: {
        countries: '',
        iags: '',
        fieldsOfInterest: [],
        status: 'approved',
      },
      // ------------
      search: '',
      from: 1,
      to: 25,
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      sortBy: null,
      direction: 'desc',
      fields: [
        { key: 'avatar', label: '' },
        { key: 'name', label: 'Full Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'fieldsOfInterest', label: 'Fields of Interest', sortable: true },
        {
          key: 'country', label: 'Country / Region', sortable: true, sortKey: 'country.name',
        },
        {
          key: 'lastLogin', label: 'Last Logged', sortable: true, sortKey: 'last_login_at',
        },
      ],
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      technicalCommittees: 'coordinatingCommittees/technicalCommitteeList',
      addressTypes: 'addressTypes/data',
      contactTypes: 'contactTypes/data',
    }),
    ...mapState({
      iAG: (state) => state.industryAcademiaGovernment.data,
      genders: (state) => state.genders.data,
    }),
    exportCsvCaption() {
      return this.exporting ? 'Exporting...' : 'Export CSV';
    },
    exportCsvDisabled() {
      return this.refreshing || this.exporting;
    },
    areFiltersDisabled() {
      return this.refreshing;
    },
  },
  watch: {
    currentPage(val, old) {
      if (val !== old) {
        this.refresh();
      }
    },
    search: debounce(function (term, old) { // eslint-disable-line func-names
      const minSearchTermLength = 3;
      if ((term.length >= minSearchTermLength && term !== old) || term.length === 0) {
        if (this.currentPage > 1) {
          this.currentPage = 1;
        } else {
          this.refresh();
        }
      }
    }, 500),
  },
  methods: {
    onBtnExport() {
      this.exporting = true;
      const columns = [
        'title',
        'firstname',
        'surname',
        'newsletterSubscribed',
        'affiliation',
        'industryAcademiaGovernment',
        'gender',
        'country',
        'disability',
        'career_stage',
        'job_position',
        'job_description',
        'notes',
        'notes_for_secretariat',
        'fieldsOfInterest',
        'positions',
        'last_login_at',
        'registered_at',
        'rejected_at',
        'completed_at',
        'approved_at',
      ];

      this.contactTypes.forEach((ct) => columns.push(`contact-${ct.id}`));
      this.addressTypes.forEach((at) => columns.push(`address-${at.id}`));

      const cleanFilters = this.cleanFiltersObject();
      cleanFilters.fieldsOfInterest = this.prepareFOIFilters();

      Affiliates.exportCsv({
        fields: columns,
        search: this.search,
        filter: cleanFilters,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'affiliates.csv');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.exporting = false;
      });
    },
    async refresh() {
      try {
        const cleanFilters = this.cleanFiltersObject();
        this.refreshing = true;
        cleanFilters.fieldsOfInterest = this.prepareFOIFilters();

        this.items = [];
        const {
          data: {
            data: { items },
          },
          data: {
            data: { meta },
          },
        } = await Affiliates.get(
          this.currentPage,
          this.perPage,
          this.search,
          this.sortBy,
          this.direction,
          cleanFilters,
        );

        this.items = items;

        this.perPage = parseInt(meta.perPage, 10);
        this.currentPage = parseInt(meta.currentPage, 10);
        this.totalRows = parseInt(meta.total, 10);
        this.from = parseInt(meta.from, 10);
        this.to = parseInt(meta.to, 10);
      } finally {
        this.refreshing = false;
      }
    },
    fetchAddressColumn(at) {
      return {
        headerName: `Address ${at.name}`,
        field: `address-${at.id}`,
        hide: true,
        valueGetter: (params) => {
          let addresses = params.data.addresses ?? [];
          addresses = addresses.filter((a) => a.type === at.id);

          return addresses.map((a) => a.text).join('\n');
        },
      };
    },
    fetchContactColumn(ct) {
      return {
        headerName: `Contact Info ${ct.name}`,
        field: `contact-${ct.id}`,
        hide: true,
        valueGetter: (params) => {
          let contactInfos = params.data.contactInfo ?? [];
          contactInfos = contactInfos.filter((c) => c.type === ct.id);

          return contactInfos.map((a) => a.info).join('\n');
        },
      };
    },
    tabIsActive(v) {
      return this.filters.status === v;
    },
    onCellClicked(data) {
      this.$router.push(
        {
          name: 'AffiliatesView',
          params: {
            id: data.id,
          },
        },
      );
    },
    setStatus(status) {
      if (status === this.filters.status) {
        return;
      }

      this.filters.status = status;
      if (this.currentPage > 1) {
        this.currentPage = 1;
      } else {
        this.refresh();
      }
    },
    setSortBy(sortField, sortDirection) {
      this.sortBy = sortField;
      this.direction = sortDirection;
      this.refresh();
    },
    initFilters(closeModal) {
      const filters = {
        countries: '',
        iags: '',
        fieldsOfInterest: [],
        status: this.filters.status,
      };
      this.filters = filters;

      if (this.currentPage > 1) {
        this.currentPage = 1;
      } else {
        this.refresh();
      }

      closeModal();
    },
    cleanFiltersObject() {
      return Object.entries({ ...this.filters }).reduce(
        (acc, [k, v]) => (v !== '' ? { ...acc, [k]: v } : acc),
        {},
      );
    },
    prepareFOIFilters() {
      return this.filters.fieldsOfInterest.map((foi) => `${foi.ccNumber}.${foi.number}`);
    },
    setFilters(filters) {
      this.filters = filters;

      if (this.currentPage > 1) {
        this.currentPage = 1;
      } else {
        this.refresh();
      }
    },
    applyFilters(modalClose) {
      modalClose();
    },
  },
  created() {
    this.refresh();
  },
};
</script>

<style scoped>
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: white !important;
  border: 1px solid #D8D8D8;
}
</style>
